
import { Component, Vue } from 'vue-property-decorator';
import SearchComponent from "@/components/SearchComponent.vue";
import { AdhocDash } from "@/models/AdhocDashboard";
import Axios from 'axios';

declare let $: any;
@Component({
    components: {
        SearchComponent,
    },
})
export default class AdhocDashboard extends Vue {
    private selectedDashboard: AdhocDash = new AdhocDash;
    private revealView: any;
    private startDate: Date = new Date();
    private endDate: Date = new Date();

    created() {
        this.startDate.setDate(1);
    }

    mounted() {
        this.$loadScript("https://dl.revealbi.io/reveal/libs/1.7.0/infragistics.reveal.js")
            .then(_ => {
                const envApi = process.env.VUE_APP_APIURL;

                $.ig.RVUtility.prototype.maxCellsRestriction = 10000000;
                $.ig.RevealSdkSettings.setBaseUrl(envApi ? envApi + "/" : "https://localhost:44362/");
                $.ig.RevealSdkSettings.setAdditionalHeadersProvider((url: any) => {
                    let headers: Record<string, any> = {};
                    headers["Authorization"] = Axios.defaults.headers?.Authorization ?? "";
                    headers["StartDate"] = this.startDate.getTime();
                    headers["EndDate"] = this.endDate.getTime();
                    return headers;
                });
                this.revealView = new $.ig.RevealView("#revealView");

                this.revealView.canSaveAs = false;
                this.revealView.showEditDataSource = true;
                this.revealView.onSave = (rv: any, args: any) => {
                    args.dashboardId = args.name;
                    args.saveFinished();
                };
                this.revealView.onDataSourcesRequested = this.datasourcesRequested;
            })
            .catch(err => {
                console.warn("Error mounting RevealView: ", err);
            });
    }

    dashboardSelected() {
        $.ig.RVDashboard.loadDashboard(this.selectedDashboard.dashboardId, (dashboard: any) => {
            console.log(dashboard);
            this.revealView.dashboard = dashboard ?? new $.ig.RVDashboard();
            this.revealView.onDataSourcesRequested = this.datasourcesRequested;
        });
    }

    datasourcesRequested(callback: any) {
        var sqlDataSource = new $.ig.RVSqlServerDataSource();
        sqlDataSource.id = "MySqlServerDatasource";
        sqlDataSource.title = "Scriptly";
        callback(new $.ig.RevealDataSources([sqlDataSource], [], false));
    }

    startDateUpdated(val: any) {
        this.revealView.refreshDashboardData();
    }

    endDateUpdated(val: any) {
        this.revealView.refreshDashbaordData();
    }
};
